import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";

const Header = () => {
  const [nav, setNav] = useState();
  const [tab2, setTab2] = useState();
  const navToggle = () => {
    setNav(!nav);
  };

  const allClose = () => {
    setNav(false);
  };

  return (
    <div>
      <header>
        <div className="header position-relative">
          <div className="container">
            <nav>
              <div className="nav-left">
                <div className="logo  ">
                  <NavLink onClick={allClose} to="/">
                    <img src="/images/logo.png" alt="logo" />
                  </NavLink>
                </div>
              </div>
              <div className="nav-right">
                <div className={nav ? "menu active" : "menu "}>
                  <ul className="main-ul mb-0">
                    <li>
                      <NavLink onClick={allClose} to="/">
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink onClick={allClose} to="/about-us">
                        Who we are
                      </NavLink>
                    </li>

                    <li>
                      <NavLink onClick={allClose} to="/showroom">
                        Categories
                      </NavLink>
                      <div className={tab2 ? "drop  open" : "drop"}>
                        <div className="drop-content-wrapper">
                          <ul className="main-page-link-wrapper">
                            <li>
                              <NavLink to="/modern">
                                Modern
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/transitional">
                                Transitional
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/traditional">
                                Traditional
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/unshaped">
                                Unshaped
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/solid">
                                Solid
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>

                    <li>
                      <NavLink onClick={allClose} to="/contact-us">
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </div>

                <div className="bar">
                  <span onClick={() => navToggle()}>
                    <FaBarsStaggered />
                  </span>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;

import React from "react";
import { NavLink } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const OurServices = () => {
  return (
    <section>
      <div className="container py-5 service-wrapper1">
        <div className="heading text-center pb-4">
        <span>Our Style</span>
          <h2> Spotlight</h2>
        
        </div>
        <div className="row">
          <div className="col-lg-4 md-6 mt-4">
            <div className="wrapper">
              <div className="img-wrapper">
                <img src="/images/home/spotlight.jpg" alt="" />
              </div>
              <div className="text-wrapper">
              <p>Genesis Collection </p>
              </div>
              
            </div>
          </div>
          <div className="col-lg-4  md-6 mt-4">
            <div className="wrapper">
              <div className="img-wrapper">
                <img src="/images/home/spotlight2.jpg" alt="" />
              </div>
              <div className="text-wrapper">
              <p>Neutral Spring Rugs </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4  md-6 mt-4">
            <div className="wrapper">
              <div className="img-wrapper">
                <img src="/images/home/spotlight3.jpg" alt="" />
              </div>
              <div className="text-wrapper">
              <p>Genesis Collection </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;

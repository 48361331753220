import React, { useEffect } from "react";
import "./common.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Forme from "../components/FormComponent";
import { NavLink } from "react-router-dom";
import {
  FaLinkedin,
  FaSquareInstagram,
  FaLocationDot,
  FaPhone,
  FaRegEnvelope,
  FaSquareXTwitter,
} from "react-icons/fa6";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { FaFacebookSquare } from "react-icons/fa";
import CourseBanner from "../components/CourseBanner";

const Contact = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const bannerData = {
    image: "/images/contact-banner.jpg",
    heading: "Contact Us",
    heading2: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique atque amet consequatur facere quod iure obcaecati, nihil rerum inventore culpa incidunt tenetur, aut eos recusandae, ullam eaque est voluptatum ea veniam dolorem perspiciatis quos sit excepturi ab! Inventore id quas dignissimos eaque, tenetur ratione laboriosam excepturi sint, optio ex magni!",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>
          Contact  | Get in Touch for Showroom Design Services
        </title>
        <meta
          name="description"
          content="Ready to elevate your showroom design? Contact The Noor Art today. Our team is eager to assist with your queries and collaborate on creating a space that truly reflects your brand's essence"
        />
      </Helmet>
      <CourseBanner {...bannerData} />

      <div className="bg2">
        <div className="container py-5 ">
          <div className="row contact-wrapper">
            <div className="col-md-6 py-5">
              <Forme display="d-none" />
            </div>
            <div className="col-md-6 footer-info py-4">
              <div className="footer-wrapper px-4">
                <div className="wrapper">
                  <h2 className="text-white">Contact Info</h2>
                  <ul className="ps-0">
                  <li className="d-flex mt-4">
                    <span className="icon-box me-2">
                      <FaLocationDot />
                    </span>
                    <span>
                      <p className="">
                        Ha no 41 Gf street no 1 villaage sher pur new Delhi
                        110094
                      </p>
                    </span>
                  </li>

                 
                  <li className="d-flex">
                    <span className="icon-box me-2">
                      <FaPhone />
                    </span>
                    <span>
                      <NavLink
                        className="effect effect-white ms-2"
                        to="tel:123456789"
                      >
                      123456789
                      </NavLink>
                    </span>
                  </li>
                  

                  <li>
                    <span className="icon-box me-2">
                      <TbDeviceLandlinePhone />
                    </span>
                    <span>
                      <NavLink
                        className="effect effect-white ms-2"
                        to="tel:123456789"
                      >
                        123456789
                      </NavLink>
                    </span>
                  </li>
                  <li>
                    <span className="icon-box me-2">
                      <FaRegEnvelope />
                    </span>
                    <NavLink
                      className="effect effect-white ms-2"
                      to="mailto:test@gmail.com"
                      target="_blank"
                      rel="noopener"
                    >
                    test@gmail.com
                    </NavLink>
                  </li>
                </ul>
                <div className="socal">
                  <p className="mb-0">
                    <strong>Follow Us</strong>
                  </p>
                  <NavLink
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaLinkedin />
                  </NavLink>
                  <NavLink
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaFacebookSquare />
                  </NavLink>
                  <NavLink
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaSquareInstagram />
                  </NavLink>

                  <NavLink
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaSquareXTwitter />
                  </NavLink>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

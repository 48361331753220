import React from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";

const WhySlider = () => {
  const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    speed: 1000,
  };
  return (
    
      <Slider {...settings}>
        <div className="slide-wrapper">
          <div className="item ">
            <div className="row d-flex align-items-center">
              <div className="col-md-6 px-5">
                <div className="wrapper">
                  <div className="text-wrapper">
                    <h3>Imagination</h3>
                    <p>
                    What sets us apart from other web design and development companies that you might be considering? Our team of experienced professionals is dedicated to delivering customized 
                    </p>
                    <NavLink className="button" to="/">
                    <span class="text">Contact Us</span>
                    </NavLink>
                   
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="img-wrapper">
                    <img src="/images/home/lroom.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slide-wrapper">
        <div className="item ">
          <div className="row d-flex align-items-center">
            <div className="col-md-6 px-5">
              <div className="wrapper">
                <div className="text-wrapper">
                  <h3>Imagination</h3>
                  <p>
                  What sets us apart from other web design and development companies that you might be considering? Our team of experienced professionals is dedicated to delivering customized 
                  </p>
                  <NavLink className="button" to="/">
                  <span class="text">Contact Us</span>
                  </NavLink>
                 
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/home/kroom.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slide-wrapper">
      <div className="item ">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 px-5">
            <div className="wrapper">
              <div className="text-wrapper">
                <h3>Imagination</h3>
                <p>
                What sets us apart from other web design and development companies that you might be considering? Our team of experienced professionals is dedicated to delivering customized 
                </p>
                <NavLink className="button" to="/">
                <span class="text">Contact Us</span>
                </NavLink>
               
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="wrapper">
              <div className="img-wrapper">
                <img src="/images/home/broom.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      
    
      </Slider>
    
  );
};

export default WhySlider;

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
const HomeCategories = () => {
  const [popupImage, setPopupImage] = useState(null);

  const settings = {
    infinite: true,
    dots: true,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 1000,
    // autoplay: true,
    // autoplaySpeed: 3000,
    // pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };



  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        closePopup();
      }
    };

    if (popupImage) {
      window.addEventListener("keydown", handleEscape);
    } else {
      document.body.style.overflow = "unset";
    }

    return () => window.removeEventListener("keydown", handleEscape);
  }, [popupImage]);

  const handleImageClick = (image) => {
    setPopupImage(`${image}`);
  };

  const closePopup = () => {
    setPopupImage(null);
  };
  return (
    <section className="py-5 bg2">
      <div className="container categories-wrapper">
        <div className="heading text-center pb-4">
          <span>Our Latest</span>
          <h2>Categories</h2>
        </div>

        <div className="our-category">
          <div className="img-wrapper">
            <img src="/images/home/category.jpg" alt="" />
            <p className="text-center mt-3">Modern</p>
          </div>
          <div className="img-wrapper">
            <img src="/images/home/category2.jpg" alt="" />
            <p className="text-center mt-3">Transitional</p>
          </div>
          <div className="img-wrapper">
            <img src="/images/home/category3.jpg" alt="" />
            <p className="text-center mt-3">Unshaped</p>
          </div>
          <div className="img-wrapper">
            <img src="/images/home/category4.jpg" alt="" />
            <p  className="text-center mt-3">Traditional</p>
          </div>
          <div className="img-wrapper">
            <img src="/images/home/category5.jpg" alt="" />
            <p className="text-center mt-3">Solid
            </p>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default HomeCategories;

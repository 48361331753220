import React, { useEffect } from "react";

import "./home.css";
import HomeBanner from "../components/HomeBanner";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import HomeCategories from "../components/HomeCategories";
import { FaAngleRight } from "react-icons/fa";
import OurServices from "../components/OurServices";
import Testimonials from "../components/Testimonials";
import WhySlider from "../components/WhySlider";
const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="page-content">
      <Helmet>
        <title>
         Agrerugs & Handicraft
        </title>
        <meta
          name="description"
          content="Welcome to The Noor Art – Your destination for bespoke showroom designs. Specializing in optical, watch, and jewelry displays, we bring creativity and functionality together to transform your retail space."
        />
      </Helmet>

      <div className="home-banner-wrapper">
        <HomeBanner />
      </div>

      <section className="bg2">
        <div className="container py-5">
          <div className="heading text-center pb-4">
            <span>Our Latest</span>
            <h2>Product</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img-wrapper">
                <img src="/images/home/banner-b1.jpg" alt="" />
              </div>
              <h3 className="text-center">Spring Rugs 2024</h3>
            </div>

            <div className="col-md-6">
              <div className="img-wrapper">
                <img src="/images/home/banner-b2.jpg" alt="" />
              </div>
              <h3 className="text-center">Round Rugs</h3>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-5 why-wrapper">
          <div className="heading text-center pb-4">
            <span>Our Latest</span>
            <h2>Product</h2>
          </div>
          <WhySlider />
        </div>
      </section>

      <HomeCategories />
      <OurServices />

      <section className="bg2 home-about">
      <div className="container py-5">
      <div className="row">
      <div className="col-md-6">
      <div className="img-wrapper">
      <img src="/images/home/home-about.jpg" alt="" /></div>
      </div>
      <div className="col-md-6">
      <div className="text">
      <div className="heading text-center pb-4">
          
            <h2 className="text-start">Who we are</h2>
          </div>
          <p><strong>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Delectus, molestias.</strong></p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fugiat, voluptates ex. Accusantium optio sunt natus dicta, sapiente alias voluptatibus adipisci rem? Consectetur vel consequuntur amet odio, debitis tenetur dignissimos praesentium? Odio voluptate, consequuntur autem delectus id nobis laudantium, fugiat nostrum incidunt qui aliquid, laboriosam iure molestias fugit. Repellat sint nemo magnam, consequuntur quam qui nostrum magni voluptate recusandae pariatur aliquam impedit cum neque sit sequi provident alias molestiae doloremque? Aut atque repellat, eaque inventore nesciunt tempore! Deleniti ipsa blanditiis consectetur sunt nihil eum quos numquam, dolor mollitia nesciunt. Ipsa, nulla. Nobis error repellendus illum, adipisci officia cupiditate quia laborum rem.</p>
      
      </div>
      
      </div>
      
      
      </div>


      </div>
    </section>

      <section className=" testimonial">
        <div className="container py-5">
          <div className="heading text-center pb-4">
            <span>Our Latest</span>
            <h2>TESTIMONIALS</h2>
            <p>Hear from our satisfied clients</p>
          </div>

          <Testimonials />
        </div>
      </section>


      <section className="bg2">
        <div className="container py-5 service-wrapper">
          <div className="heading text-center pb-4">
            <span>Our Latest</span>
            <h2> Blogs</h2>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Voluptas, maiores?
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-bd-6 mt-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/home/blog1.jpeg" alt="" />
                </div>
                <div className="text-wrapper">
                  <div className="text">
                    <p>April 9, 2022 No Comments</p>
                    <h3>Lorem, ipsum dolor.</h3>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Quod, exercitationem...
                    </p>

                    <NavLink className="effect" to="/">
                      Read More
                      <FaAngleRight />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-bd-6 mt-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/home/blog2.jpeg" alt="" />
                </div>
                <div className="text-wrapper">
                  <div className="text">
                    <p>April 9, 2022 No Comments</p>
                    <h3>Lorem, ipsum dolor.</h3>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Quod, exercitationem...
                    </p>

                    <NavLink className="effect" to="/">
                      Read More
                      <FaAngleRight />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-bd-6 mt-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/home/blog3.jpeg" alt="" />
                </div>
                <div className="text-wrapper">
                  <div className="text">
                    <p>April 9, 2022 No Comments</p>
                    <h3>Lorem, ipsum dolor.</h3>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Quod, exercitationem...
                    </p>

                    <NavLink className="effect" to="/">
                      Read More
                      <FaAngleRight />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;

import React from "react";
import { IoStar, IoStarHalf } from "react-icons/io5";
import Slider from "react-slick";
const Testimonials = () => {
  const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: 3,
    speed: 1000,
    // autoplay: true,
    // autoplaySpeed: 3000,
    // pauseOnHover: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
         
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
         
        }
      },
      
    ]
  };

  const ProductData = {
    data: [
      {
        img: "/images/home/user.png",
        name: "Ranvijay Yadav",
        branch: "Agra,India",
        para: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed maxime ipsam quae molestias fugiat esse assumenda labore earum molestiae, et ut officiis vel nesciunt ab?",
      },
      {
        img: "/images/home/user.png",
        name: "Ranvijay Yadav",
        branch: "Agra,India",
        para: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed maxime ipsam quae molestias fugiat esse assumenda labore earum molestiae, et ut officiis vel nesciunt ab?",
      },
      {
        img: "/images/home/user.png",
        name: "Ranvijay Yadav",
        branch: "Agra,India",
        para: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed maxime ipsam quae molestias fugiat esse assumenda labore earum molestiae, et ut officiis vel nesciunt ab?",
      },
      {
        img: "/images/home/user.png",
        name: "Ranvijay Yadav",
        branch: "Agra,India",
        para: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed maxime ipsam quae molestias fugiat esse assumenda labore earum molestiae, et ut officiis vel nesciunt ab?",
      },
      {
        img: "/images/home/user.png",
        name: "Ranvijay Yadav",
        branch: "Agra,India",
        para: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed maxime ipsam quae molestias fugiat esse assumenda labore earum molestiae, et ut officiis vel nesciunt ab?",
      },
    
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        {ProductData.data.map((item, index) => (
          <div className="testimonial-wrapper" key={index}>
          <div className="item-wrapper">
          <div className="wrapper p-3">
              <div className="person d-flex align-items-center justify-content-start">
                <div className="img">
                  <img src={item.img} alt="" />
                </div>
                <div className="person-detail ms-3">
                  <span className="d-block fw-7">{item.name}</span>
                  <span>{item.branch}</span>
                </div>
              </div>

              <div className="message">
                <div className="rating">
                  <IoStar />
                  <IoStar />
                  <IoStar />
                  <IoStar />
                  <IoStarHalf />
                </div>
                <div className="para mt-3">
                  <p>{item.para}</p>
                </div>
              </div>
            </div>
          </div>
            
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;

import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaLinkedin,
  FaSquareInstagram,
  FaLocationDot,
  FaPhone,
  FaRegEnvelope,
  FaSquareXTwitter,
} from "react-icons/fa6";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { FaFacebookSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-wrapper">
              <div className="wrapper">
                <div className="footer-logo">
                  <img src="/images/logo.png" alt="logo" />
                </div>
                <ul className="ps-0">
                  <li className="d-flex mt-4">
                    <span className="icon-box me-2">
                      <FaLocationDot />
                    </span>
                    <span>
                      <p className="">
                        Ha no 41 Gf street no 1 villaage sher pur new Delhi
                        110094
                      </p>
                    </span>
                  </li>

                 
                  <li className="d-flex">
                    <span className="icon-box me-2">
                      <FaPhone />
                    </span>
                    <span>
                      <NavLink
                        className="effect effect-white ms-2"
                        to="tel:123456789"
                      >
                      123456789
                      </NavLink>
                    </span>
                  </li>
                  

                  <li>
                    <span className="icon-box me-2">
                      <TbDeviceLandlinePhone />
                    </span>
                    <span>
                      <NavLink
                        className="effect effect-white ms-2"
                        to="tel:123456789"
                      >
                        123456789
                      </NavLink>
                    </span>
                  </li>
                  <li>
                    <span className="icon-box me-2">
                      <FaRegEnvelope />
                    </span>
                    <NavLink
                      className="effect effect-white ms-2"
                      to="mailto:test@gmail.com"
                      target="_blank"
                      rel="noopener"
                    >
                    test@gmail.com
                    </NavLink>
                  </li>
                </ul>
                <div className="socal">
                  <p className="mb-0">
                    <strong>Follow Us</strong>
                  </p>
                  <NavLink
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaLinkedin />
                  </NavLink>
                  <NavLink
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaFacebookSquare />
                  </NavLink>
                  <NavLink
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaSquareInstagram />
                  </NavLink>

                  <NavLink
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaSquareXTwitter />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
         
            <div className="wrapper">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113579.63882693018!2d77.89761024173265!3d27.176303144496366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39740d857c2f41d9%3A0x784aef38a9523b42!2sAgra%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1710587006872!5m2!1sen!2sin"
                width="100%"
                height="390"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright">
        <div className="container">
          <p className="text-center mb-0">©2023-24 Agrerugs & Handicraft</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
